<template>
    <b-card no-body class="p-1">
      <b-card-header>
        <b-card-title>LumberJack Logs</b-card-title>
            <b-card-text class="w-50 mr-25 mb-0">
            <b-row class="flex align-items-center">
                <b-col md="2" class="pr-0">Period</b-col>
                <b-col md="10">
                <div class="d-flex custom-date" v-if="date === 'custom'">
                    <v-select v-model="date" :options="dateOptions" :reduce="(val) => val.value" class="w-50" />
                    <flat-pickr v-model="customDate" class="form-control" :config="{ mode: 'range' }" placeholder="Select Date" />
                </div>
                <v-select v-else v-model="date" :options="dateOptions" :reduce="(val) => val.value" />
                </b-col>
            </b-row>
            </b-card-text>
        </b-card-header>
        <b-card-body>
            <b-row>
                <b-col md="12" v-for="log in logs" :key="log._id">
                    <div class="d-flex justify-content-between pb-2">
                        <div>
                            <div>{{ log.action }}</div>
                            <small>{{ log.url }}</small>
                        </div>
                        <div>{{ log.date }}</div>
                    </div>
                </b-col>
            </b-row>
        </b-card-body>
    </b-card>
  </template>
  
  <script>
  import { BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import flatPickr from 'vue-flatpickr-component'
  
  export default {
    components: {
      BRow,
      BCol,
      BCard,
      BCardHeader,
      BCardTitle,
      BCardText,
      BCardBody,
      BMedia,
      BAvatar,
      BMediaAside,
      BMediaBody,
      vSelect,
      flatPickr,
    },
    props: {
      userId: Number,
    },
    data() {
      return {
        logs: [],
        from_date: null,
        to_date: null,
        date: null,
        dateOptions: [
          { label: 'Last 7 Days', value: 'lastseven' },
          { label: 'Last 30 Days', value: 'lastthirty' },
          { label: 'This Month', value: 'thismonth' },
          { label: 'Last Month', value: 'lastmonth' },
          { label: 'This Year', value: 'thisyear' },
          { label: 'Last 365 Days', value: 'last365days' },
          { label: 'All Time', value: 'all' },
          { label: 'Custom', value: 'custom' },
        ],
        customDate: null,
      }
    },
    watch: {
      date: function (newDate, oldDate) {
        if (newDate !== 'custom') {
          if (newDate === 'lastseven') {
            this.from_date = this.$moment().subtract(7, 'd').unix()
            this.to_date = this.$moment().unix()
          } else if (newDate === 'lastthirty') {
            this.from_date = this.$moment().subtract(30, 'd').unix()
            this.to_date = this.$moment().unix()
          } else if (newDate === 'thismonth') {
            this.from_date = this.$moment().startOf('month').unix()
            this.to_date = this.$moment().unix()
          } else if (newDate === 'lastmonth') {
            this.from_date = this.$moment().startOf('month').subtract(1, 'M').unix()
            this.to_date = this.$moment().endOf('month').subtract(1, 'M').unix()
          } else if (newDate === 'thisyear') {
            this.from_date = this.$moment().startOf('year').unix()
            this.to_date = this.$moment().unix()
          } else if (newDate === 'last365days') {
            this.from_date = this.$moment().subtract(365, 'd').unix()
            this.to_date = this.$moment().unix()
          } else if (newDate === 'all') {
            this.from_date = 0
            this.to_date = this.$moment().unix()
          }
          this.fetch()
        }
      },
      customDate: function (newDate, oldDate) {
        const [from, to] = newDate.split(' to ')
        this.from_date = this.$moment(from).unix()
        this.to_date = this.$moment(to).unix()
        this.fetch()
      },
    },
    mounted() {
      this.from_date = this.$moment().subtract(7, 'd').unix()
      this.to_date = this.$moment().unix()
      this.date = 'lastseven'
    },
    methods: {
      fetch() {
        this.$http.get(`/user/${this.userId}/logs/${this.from_date}/${this.to_date}`).then((res) => {
          this.logs = res.data
        })
      },
    },
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  .custom-date {
    [role='combobox'] {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    input[type='text'] {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: 0;
    }
  }
  </style>
  