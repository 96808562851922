<template>
	<b-card no-body class="p-1">
		<b-card-header>
			<b-card-title>Payment Logs</b-card-title>
			<b-card-text class="w-50 mr-25 mb-0">
				<b-row class="flex align-items-center">
					<b-col md="4" class="pr-0">Filter by Booking ID</b-col>
					<b-col md="8">
						<b-form-input v-model="bookingId" placeholder="eg. 123" />
					</b-col>
				</b-row>
			</b-card-text>
		</b-card-header>
		<b-card-body>
			<b-row>
				<b-col md="12" v-for="log in logs" :key="log.id">
					<div class="d-flex justify-content-between pb-2">
						<div>
							<div>Booking ID</div>
							<small>{{ log.booking_id }}</small>
						</div>
						<div>
							<div>Action</div>
							<small>{{ log.action }}</small>
						</div>
						<div>
							<div>Payment Gateway</div>
							<small>{{ log.payment_gateway }}</small>
						</div>
						<div>
							<div>Date</div>
							<small>{{ log.created_at }}</small>
						</div>
						<div>
							<div>RAW Log</div>
							<small class="cursor-pointer" @click="showRawLog(log)">View</small>
						</div>
					</div>
				</b-col>
			</b-row>
		</b-card-body>
		<b-modal v-model="modal.show" size="lg" :title="modal.title">
			<b-row>
				<b-col cols="12" class="mb-md-0 mb-2" style="word-break: break-all"> {{ modal.content }} </b-col>
			</b-row>
		</b-modal>
	</b-card>
</template>

<script>
import { BModal, BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BFormInput } from "bootstrap-vue";

export default {
	components: {
		BModal,
		BRow,
		BCol,
		BCard,
		BCardHeader,
		BCardTitle,
		BCardText,
		BCardBody,
		BMedia,
		BAvatar,
		BMediaAside,
		BMediaBody,
		BFormInput,
	},
	props: {
		userId: Number,
	},
	data() {
		return {
			logs: [],
			bookingId: null,
			timer: null,
			modal: {
				show: false,
				content: "",
				title: "",
			},
		};
	},
	watch: {
		bookingId: function (newId, oldId) {
			clearTimeout(this.timer);
			this.timer = setTimeout(() => {
				this.fetch();
			}, 1000);
		},
	},
	mounted() {
		this.fetch();
	},
	methods: {
		fetch() {
			if (this.bookingId) {
				this.$http.get(`/user/${this.userId}/payment/logs/${this.bookingId}`).then((res) => {
					this.logs = res.data;
				});
			} else {
				this.$http.get(`/user/${this.userId}/payment/logs`).then((res) => {
					this.logs = res.data;
				});
			}
		},
		showRawLog(log) {
			this.modal.show = true;
			this.modal.content = log.response;
			this.modal.title = `Log #${log.id}`;
		},
	},
};
</script>
