<template>
  <b-card no-body class="p-1">
    <b-card-header>
      <b-card-title>Activities</b-card-title>
      <b-card-text class="w-50 mr-25 mb-0">
        <b-row class="flex align-items-center">
          <b-col md="2" class="pr-0">Period</b-col>
          <b-col md="10">
            <div class="d-flex custom-date" v-if="date === 'custom'">
              <v-select v-model="date" :options="dateOptions" :reduce="(val) => val.value" class="w-50" />
              <flat-pickr v-model="customDate" class="form-control" :config="{ mode: 'range' }" placeholder="Select Date" />
            </div>
            <v-select v-else v-model="date" :options="dateOptions" :reduce="(val) => val.value" />
          </b-col>
        </b-row>
      </b-card-text>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col v-for="item in activitiesItems" :key="item.subtitle" md="4" sm="8" class="mt-2 mb-md-0" :class="item.customClass">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" :variant="item.color">
                <feather-icon size="24" :icon="item.icon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
      <b-row class="mt-2 px-1">
        <b-col v-for="item in extraActivitiesItems" :key="item.subtitle" md="3" sm="5" class="mt-2 mb-md-0" :class="item.customClass">
          <b-media no-body>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder font-small-4 mb-0">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-2 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody } from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    vSelect,
    flatPickr,
  },
  props: {
    userId: Number,
    currency: String,
  },
  data() {
    return {
      activitiesItems: [],
      extraActivitiesItems: [],
      from_date: null,
      to_date: null,
      date: null,
      dateOptions: [
        { label: 'Last 7 Days', value: 'lastseven' },
        { label: 'Last 30 Days', value: 'lastthirty' },
        { label: 'This Month', value: 'thismonth' },
        { label: 'Last Month', value: 'lastmonth' },
        { label: 'This Year', value: 'thisyear' },
        { label: 'Last 365 Days', value: 'last365days' },
        { label: 'All Time', value: 'all' },
        { label: 'Custom', value: 'custom' },
      ],
      customDate: null,
    }
  },
  watch: {
    date: function (newDate, oldDate) {
      if (newDate !== 'custom') {
        if (newDate === 'lastseven') {
          this.from_date = this.$moment().subtract(7, 'd').unix()
          this.to_date = this.$moment().unix()
        } else if (newDate === 'lastthirty') {
          this.from_date = this.$moment().subtract(30, 'd').unix()
          this.to_date = this.$moment().unix()
        } else if (newDate === 'thismonth') {
          this.from_date = this.$moment().startOf('month').unix()
          this.to_date = this.$moment().unix()
        } else if (newDate === 'lastmonth') {
          this.from_date = this.$moment().startOf('month').subtract(1, 'M').unix()
          this.to_date = this.$moment().endOf('month').subtract(1, 'M').unix()
        } else if (newDate === 'thisyear') {
          this.from_date = this.$moment().startOf('year').unix()
          this.to_date = this.$moment().unix()
        } else if (newDate === 'last365days') {
          this.from_date = this.$moment().subtract(365, 'd').unix()
          this.to_date = this.$moment().unix()
        } else if (newDate === 'all') {
          this.from_date = 0
          this.to_date = this.$moment().unix()
        }
        this.fetch()
      }
    },
    customDate: function (newDate, oldDate) {
      const [from, to] = newDate.split(' to ')
      this.from_date = this.$moment(from).unix()
      this.to_date = this.$moment(to).unix()
      this.fetch()
    },
  },
  mounted() {
    this.from_date = this.$moment().subtract(7, 'd').unix()
    this.to_date = this.$moment().unix()
    this.date = 'lastseven'
  },
  methods: {
    fetch() {
      this.$http.get(`/user/${this.userId}/activities/${this.from_date}/${this.to_date}`).then((res) => {
        //Main Activity Stats
        this.activitiesItems = []
        this.activitiesItems.push({
          icon: 'HashIcon',
          color: 'light-primary',
          title: res.data.total_activities,
          subtitle: 'Total Activities',
        })
        this.activitiesItems.push({
          icon: 'CheckIcon',
          color: 'light-success',
          title: res.data.total_published_activites,
          subtitle: 'Published Activities',
        })
        this.activitiesItems.push({
          icon: 'XIcon',
          color: 'light-dark',
          title: res.data.total_unpublished_activites,
          subtitle: 'Unpublished Activities',
        })
        this.activitiesItems.push({
          icon: 'SunIcon',
          color: 'light-warning',
          title: res.data.total_open_dates_activities,
          subtitle: 'Open Activities',
        })
        this.activitiesItems.push({
          icon: 'MaximizeIcon',
          color: 'light-info',
          title: res.data.total_fixed_dates_activities,
          subtitle: 'Fixed Activities',
        })
        this.activitiesItems.push({
          icon: 'HeartIcon',
          color: 'light-danger',
          title: res.data.total_special_dates_activities,
          subtitle: 'Activities with Special Dates',
        })

        //Minor Activities Stats without Icon
        this.extraActivitiesItems = []
        this.extraActivitiesItems.push({
          title: res.data.slots_per_activity,
          subtitle: 'Slots Per Activity',
        })
        this.extraActivitiesItems.push({
          title: res.data.days_per_activity,
          subtitle: 'Days Per Activity',
        })
        this.extraActivitiesItems.push({
          title: res.data.tickets_per_activity,
          subtitle: 'Tickets Per Activity',
        })
        this.extraActivitiesItems.push({
          title: res.data.group_tickets_per_activity,
          subtitle: 'Group Tickets Per Activity',
        })
        this.extraActivitiesItems.push({
          title: res.data.special_per_activity,
          subtitle: 'Special Per Activity',
        })
        this.extraActivitiesItems.push({
          title: res.data.pickup_locations_per_activities,
          subtitle: 'Pickup Locations Per Activity',
        })
        this.extraActivitiesItems.push({
          title: res.data.seo_per_activities,
          subtitle: 'SEO Per Activity',
        })
        this.extraActivitiesItems.push({
          title: res.data.description_per_activities,
          subtitle: 'Descriptions Per Activity',
        })
        this.extraActivitiesItems.push({
          title: res.data.header_per_activities,
          subtitle: 'Header Per Activity',
        })
        this.extraActivitiesItems.push({
          title: res.data.gallery_per_activities,
          subtitle: 'Gallery Per Activity',
        })
        this.extraActivitiesItems.push({
          title: res.data.thumbnail_per_activities,
          subtitle: 'Thumbnail Per Activity',
        })
        this.extraActivitiesItems.push({
          title: res.data.videos_per_activites,
          subtitle: 'Videos Per Activity',
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.custom-date {
  [role='combobox'] {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  input[type='text'] {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
  }
}
</style>
